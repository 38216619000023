import React, { useReducer, createContext, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { stepOptions } from "constants/salesConfiguratorData";
import usePriceStore from "app/stores/usePriceStore";

export const SalesConfiguratorContext = createContext();

const CHANGE_PARENT_VALUE = "CHANGE_PARENT_VALUE";
const CHANGE_CHILD_VALUE = "CHANGE_CHILD_VALUE";
const CHANGE_CUSTOM_VALUE = "CHANGE_CUSTOM_VALUE";
const TOGGLE_CHECKBOX_VALUE = "TOGGLE_CHECKBOX_VALUE";
const CHANGE_CHECKBOX_CHILD_VALUE = "CHANGE_CHECKBOX_CHILD_VALUE";
const CHANGE_CHECKBOX_QUANTITY = "CHANGE_CHECKBOX_QUANTITY";
const RESET_CONFIGURATOR = "RESET_CONFIGURATOR";
const CHANGE_COMMENT_VALUE = "CHANGE_COMMENT_VALUE";

const reducer = (state, action) => {

  if (action.type === CHANGE_PARENT_VALUE) {
    const { stepId, parentValue } = action.payload;

    const itemIndex = state.steps.findIndex((item) => item.id === stepId);

    const steps = state.steps.map((item, index) => {
      if (index < itemIndex) return item;

      const selectedOption = item.options.values.find(
        (option) => option.id === parentValue
      );

      return {
        ...item,
        value:
          index === itemIndex
            ? {
              ...item.value,
              parentValue: parentValue,
              childValue: selectedOption.childOptions
                ? selectedOption.childOptions[0].id
                : null,
            }
            : null,
      };
    });

    return { ...state, steps };
  }

  if (action.type === CHANGE_CHILD_VALUE) {
    const { stepId, childValue } = action.payload;

    const itemIndex = state.steps.findIndex((item) => item.id === stepId);

    const steps = state.steps.map((item, index) => {
      if (index < itemIndex) return item;

      return {
        ...item,
        value:
          index === itemIndex
            ? {
              ...item.value,
              childValue: childValue,
            }
            : item.value,
      };
    });

    return { ...state, steps };
  }

  if (action.type === CHANGE_CHECKBOX_CHILD_VALUE) {
    const { stepId, childValue, parentValue } = action.payload;

    const itemIndex = state.steps.findIndex((item) => item.id === stepId);

    const steps = state.steps.map((item, index) => {
      if (index < itemIndex) return item;
      const updatedValue = item.value?.map((x) =>
        x.parentValue === parentValue ? { ...x, childValue: childValue } : x
      );

      return {
        ...item,
        value: index === itemIndex ? updatedValue : item.value,
      };
    });

    return { ...state, steps };
  }

  if (action.type === CHANGE_CUSTOM_VALUE) {
    const { stepId, value } = action.payload;

    return {
      ...state,
      customValues: { ...state.customValues, [stepId]: value },
    };
  }

  if (action.type === CHANGE_COMMENT_VALUE) {
    const { stepId, value } = action.payload;

    return {
      ...state,
      commentValues: { ...state.commentValues, [stepId]: value },
    };
  }

  if (action.type === TOGGLE_CHECKBOX_VALUE) {
    const { stepId, optionId, checked } = action.payload;

    const itemIndex = state.steps.findIndex((item) => item.id === stepId);

    const steps = state.steps.map((item, index) => {
      if (index < itemIndex) {
        return item;
      }

      if (index > itemIndex) {
        return {
          ...item,
          value: null,
        };
      }

      const selectedOption = item.options.values.find(
        (option) => option.id === optionId
      );

      const value = checked
        ? [
          ...(item.value ?? []),
          {
            parentValue: optionId,
            childValue: selectedOption.childOptions
              ? selectedOption.childOptions[0].id
              : null,
            quantity: selectedOption.hasQuantity ? 1 : null,
          },
        ]
        : item.value.filter((item) => item.parentValue !== optionId);

      return {
        ...item,
        value: value.length === 0 ? null : value,
      };
    });

    return { ...state, steps };
  }

  if (action.type === CHANGE_CHECKBOX_QUANTITY) {
    const { stepId, optionId, quantity } = action.payload;

    const itemIndex = state.steps.findIndex((item) => item.id === stepId);

    const steps = state.steps.map((item, index) => {
      if (index < itemIndex) {
        return item;
      }

      if (index > itemIndex) {
        return {
          ...item,
          value: null,
        };
      }

      const updatedValue = item.value?.map((x) =>
        x.parentValue === optionId ? { ...x, quantity: quantity } : x
      );

      return {
        ...item,
        value: index === itemIndex ? updatedValue : item.value,
      };
    });
    return { ...state, steps };
  }

  if (action.type === RESET_CONFIGURATOR) {
    return {
      steps: stepOptions,
      customValues: {},
      commentValues: {},
    };
  }

  return state;
};

export const SalesConfiguratorProvider = ({ children }) => {
  const [{ steps, customValues, commentValues }, dispatch] = useReducer(
    reducer,
    {
      steps: stepOptions,
      customValues: {},
      commentValues: {},
    }
  );

  const changeParentValue = useCallback(
    (parentValue, stepId) => {
      dispatch({
        type: CHANGE_PARENT_VALUE,
        payload: {
          parentValue,
          stepId,
        },
      });
    },
    [dispatch]
  );

  const changeChildValue = useCallback(
    (childValue, stepId) => {
      dispatch({
        type: CHANGE_CHILD_VALUE,
        payload: {
          childValue,
          stepId,
        },
      });
    },
    [dispatch]
  );

  const changeCustomValue = useCallback(
    (value, stepId) => {
      dispatch({
        type: CHANGE_CUSTOM_VALUE,
        payload: {
          value,
          stepId,
        },
      });
    },
    [dispatch]
  );

  const changeCommentValue = useCallback(
    (value, stepId) => {
      dispatch({
        type: CHANGE_COMMENT_VALUE,
        payload: {
          value,
          stepId,
        },
      });
    },
    [dispatch]
  );

  const toggleCheckboxValue = useCallback(
    (optionId, stepId, checked) => {
      dispatch({
        type: TOGGLE_CHECKBOX_VALUE,
        payload: {
          optionId,
          stepId,
          checked,
        },
      });
    },
    [dispatch]
  );

  const changeCheckboxQuantity = useCallback(
    (optionId, stepId, quantity) => {
      dispatch({
        type: CHANGE_CHECKBOX_QUANTITY,
        payload: {
          optionId,
          stepId,
          quantity,
        },
      });
    },
    [dispatch]
  );

  const changeCheckboxChildValue = useCallback(
    (childValue, stepId, parentValue) => {
      dispatch({
        type: CHANGE_CHECKBOX_CHILD_VALUE,
        payload: {
          childValue,
          stepId,
          parentValue,
        },
      });
    },
    [dispatch]
  );

  const resetConfigurator = useCallback(() => {
    dispatch({
      type: RESET_CONFIGURATOR,
    });
  }, [dispatch]);

  const selectedValues = useMemo(() => {
    return steps
      .map((option) => option.value?.parentValue)
      .filter((value) => value);
  }, [steps]);

  const selectedChildValues = useMemo(() => {
    return steps
      .map((option) => option.value?.childValue)
      .filter((value) => value);
  }, [steps]);

  const selectedSteps = useMemo(() => {
    return steps.filter((item) => item.value).map((item) => item.id);
  }, [steps]);

  const prices = usePriceStore((state) => state.prices);
  const selectedNameValueSteps = useMemo(() => {
    return steps
      .filter((option) => option.value)
      .map((item) => {
        //If is checkbox step
        if (Array.isArray(item.value)) {
          const values = item.value.map((valueItem) => {
            const optionValue = item.options.values.find(
              (option) => option.id === valueItem.parentValue
            );

            const parentValue = optionValue.name;
            const childValue = optionValue.childOptions?.find(
              (option) => option.id === valueItem.childValue
            ).name;
            const price =
              prices.get(
                valueItem.childValue
                  ? valueItem.childValue
                  : valueItem.parentValue
              ) * (valueItem.quantity ? valueItem.quantity : 1);

            return {
              parentValue,
              childValue: childValue ? childValue : null,
              quantity: valueItem.quantity,
              price: price,
            };
          });

          const valuesAsString = values
            .map((value) => {
              return value.childValue
                ? `${value.parentValue} (${value.childValue})`
                : value.quantity
                  ? `${value.parentValue} (${value.quantity} pcs)`
                  : value.parentValue;
            })
            .join(", ");


          const stepPrice = values.some(
            (value) => isNaN(value.price)
          )
            ? "ask EG for price" : values.reduce(
              (acc, o) => acc + parseInt(o.price ? o.price : 0),
              0
            );

          return {
            name: item.name,
            value: valuesAsString,
            price: stepPrice ? `${stepPrice}` : null,
          };
        }

        const value = item.options.values.find(
          (option) => option.id === item.value.parentValue
        );

        const parentValue = value.name;
        const childValue = value.childOptions?.find(
          (option) => option.id === item.value.childValue
        );

        const specificPriceChild = selectedChildValues.find(
          (selectedChildValue) =>
            value?.specificPrice?.includes(selectedChildValue)
        );

        const specificPriceParent = selectedValues.find((selectedValue) =>
          value?.specificPrice?.includes(selectedValue)
        );


        const childPrice = prices.get(specificPriceParent ? `${childValue?.id}-${specificPriceParent}` : childValue?.id)

        const parentPrice = prices.get(specificPriceChild ? `${value?.id}-${specificPriceChild}` : value?.id)


        const price = prices.get(childValue ? childValue.id : value.id);

        return {
          name: item.name,
          price: price ? `${childValue ? childPrice : parentPrice}` : null,
          value: childValue?.name
            ? `${parentValue} (${childValue?.name})`
            : parentValue,
          commentValue: commentValues[item.id]
            ? `${item.commentField}: ${commentValues[item.id]}`
            : null,
        };
      });
  }, [commentValues, prices, selectedChildValues, selectedValues, steps]);


  const totalPrice = selectedNameValueSteps.reduce((acc, o) => {
    return acc + parseInt(o.price && !isNaN(Number(o.price)) ? o.price : 0);
  }, 0);



  const value = useMemo(
    () => ({
      steps,
      changeParentValue,
      changeChildValue,
      selectedValues,
      selectedChildValues,
      selectedSteps,
      changeCustomValue,
      customValues,
      toggleCheckboxValue,
      resetConfigurator,
      selectedNameValueSteps,
      changeCheckboxChildValue,
      changeCheckboxQuantity,
      changeCommentValue,
      commentValues,
      totalPrice,
    }),
    [
      steps,
      changeParentValue,
      changeChildValue,
      selectedValues,
      selectedChildValues,
      selectedSteps,
      changeCustomValue,
      customValues,
      toggleCheckboxValue,
      resetConfigurator,
      selectedNameValueSteps,
      changeCheckboxChildValue,
      changeCheckboxQuantity,
      changeCommentValue,
      commentValues,
      totalPrice,
    ]
  );

  return (
    <SalesConfiguratorContext.Provider value={value}>
      {children}
    </SalesConfiguratorContext.Provider>
  );
};

SalesConfiguratorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
