import * as React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const QuantitySelector = ({
  optionId,
  selectedQuantity,
  value,
  handleCheckboxQuantityChange,
  price,
}) => {
  const isOptionSelected = value?.some((item) => item.parentValue === optionId);

  return (
    <>
      <TextField
        id="pcs"
        name={optionId}
        type="number"
        disabled={!isOptionSelected}
        size="small"
        onChange={handleCheckboxQuantityChange}
        value={selectedQuantity}
        sx={{ minWidth: 70, width: 70, mr: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {price ? (
        <Typography variant="body2" color="textSecondary">
          {`pcs (${price} € / pc)`}
        </Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">
          pcs
        </Typography>
      )}
    </>
  );
};

QuantitySelector.propTypes = {
  optionId: PropTypes.string,
  value: PropTypes.array,
  handleCheckboxQuantityChange: PropTypes.func.isRequired,
  selectedQuantity: PropTypes.number,
  price: PropTypes?.number,
};

export default QuantitySelector;
