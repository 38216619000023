import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import PropTypes from "prop-types";

const ChildRadioButton = ({ childOption }) => {
  return (
    <FormControlLabel
      key={childOption.id}
      value={`${childOption.id}`}
      label={
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2">{childOption.name}</Typography>
        </Box>
      }
      control={<Radio size="small" color="primary" />}
    />
  );
};

ChildRadioButton.propTypes = {
  childOption: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default ChildRadioButton;
