import React, { useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const UnauthenticatedApp = ({ login, isPasswordLoading }) => {
  const [password, setPassword] = useState("");

  const onChange = (event) => {
    setPassword(event.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    login(password);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper elevation={3} sx={{ p: 3, minWidth: 350 }}>
        <Typography variant="h6">Veikko Nummela Pump Configurator</Typography>
        <form onSubmit={onSubmit}>
          <TextField
            fullWidth
            sx={{ my: 2 }}
            onChange={onChange}
            value={password}
            variant="outlined"
            placeholder="Password"
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isPasswordLoading}
              startIcon={
                isPasswordLoading && (
                  <CircularProgress size={16} color="primary" />
                )
              }
            >
              Login
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

UnauthenticatedApp.propTypes = {
  login: PropTypes.func,
  isPasswordLoading: PropTypes.bool,
};

export default UnauthenticatedApp;
