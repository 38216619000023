import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#850000",
    },
    client: {
      primary: "#A92725",
    },
  },
}));

const Providers = ({ children }) => {
  return (
    <Router>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </Router>
  );
};

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
