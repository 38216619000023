import { useState, useCallback } from "react";
import axios from "axios";

const key = "product-configuration-auth";

const usePasswordAuth = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [authValue, setAuthValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item;
    } catch (error) {
      return "";
    }
  });



  const login = useCallback(async (password) => {
    setIsLoading(true)
    setIsError(false)
    try {
      const response = await axios.post(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/VeikkoNummelaPasswordCheckFunction`, { input: password });
      setIsLoading(false)

      if (response.data.passwordExists === true) {
        setAuthValue("password");
        window.localStorage.setItem(key, "password");
      } else {
        window.localStorage.setItem(key, "");
      }

    } catch (error) {
      setIsError(true)
      console.log(error);
    }

  }, []);

  return { isAuth: !!authValue, login, isLoading, isError };
};

export default usePasswordAuth;
