import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import SalesConfiguratorSummary from "features/salesConfigurator/components/SalesConfiguratorSummary";
import BackIcon from "components/BackIcon";
import SalesConfiguratorOrderForm from "features/salesConfigurator/components/SalesConfiguratorOrderForm";
import Box from "@mui/material/Box";

const SalesConfiguratorCheckout = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Link style={{ textDecoration: "none" }} to="/salesConfigurator/corePump">
        <Box
          sx={{
            display: "inline-flex",
            color: "text.primary",
            mt: 1,
          }}
        >
          <Box sx={{ mr: 1 }}>
            <BackIcon />
          </Box>
          <Typography>Back to configurator</Typography>
        </Box>
      </Link>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 2, py: 2 }}>
          <SalesConfiguratorOrderForm />
        </Box>
        <Box sx={{ flex: 1, p: 2 }}>
          <SalesConfiguratorSummary showPlaceOrderButton={false} />
        </Box>
      </Box>
    </Box>
  );
};

export default SalesConfiguratorCheckout;
