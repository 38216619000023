import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

const SalesConfiguratorDescription = ({ title, value }) => {
  return (
    <Box>
      {title && (
        <Typography variant="body2" sx={{ fontWeight: "medium" }}>
          {title}
        </Typography>
      )}
      {value && (
        <Typography
          sx={{ whiteSpace: "pre-line" }}
          variant="body2"
          color="textSecondary"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </Box>
  );
};

export default SalesConfiguratorDescription;

SalesConfiguratorDescription.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};
