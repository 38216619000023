import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { SalesConfiguratorContext } from 'features/salesConfigurator/state/SalesConfiguratorContext';
import Paper from '@mui/material/Paper';
import SalesConfiguratorNextButton from 'features/salesConfigurator/components/SalesConfiguratorNextButton';
import SalesConfiguratorRadioButtonsWithCustomTextOption from 'features/salesConfigurator/components/SalesConfiguratorRadioButtonsWithCustomTextOption';
import SalesConfiguratorRadioButtons from 'features/salesConfigurator/components/SalesConfiguratorRadioButtons';
import SalesConfiguratorCheckboxes from 'features/salesConfigurator/components/SalesConfiguratorCheckboxes';
import SalesConfiguratorImages from 'features/salesConfigurator/components/SalesConfiguratorImages';
import SalesConfiguratorDescription from 'features/salesConfigurator/components/SalesConfiguratorDescription';
import Box from '@mui/material/Box';
import SalesConfiguratorSpecifications from 'features/salesConfigurator/components/SalesConfiguratorSpecifications';
import ValueImage from 'components/ValueImage';
import SalesConfiguratorCommentField from 'features/salesConfigurator/components/SalesConfiguratorCommentField';

const SalesConfiguratorStep = () => {
  const { step } = useParams();
  const [uncheckedLast, setUncheckedLast] = React.useState(false);

  const {
    steps,
    changeParentValue,
    changeChildValue,
    selectedValues,
    selectedChildValues,
    changeCustomValue,
    customValues,
    toggleCheckboxValue,
    changeCheckboxChildValue,
    changeCheckboxQuantity,
    changeCommentValue,
    commentValues,
  } = useContext(SalesConfiguratorContext);

  const stepData = steps.find((option) => option.id === step);

  const selectedOption = Array.isArray(stepData.value)
    ? stepData.options.values.find(
        (value) => value.id === stepData.value?.at(-1).parentValue
      )
    : stepData.options.values.find(
        (value) => value.id === stepData.value?.parentValue
      );

  const isAllowedStep =
    stepData.requires.length === 0 ||
    stepData.requires.some((requirement) =>
      selectedValues.includes(requirement)
    );

  const handleParentValueChange = (parentValue) => {
    changeParentValue(parentValue, step);
    setUncheckedLast(false);
  };

  const handleChildValueChange = (childValue) => {
    changeChildValue(childValue, step);
  };

  const handleCheckboxChildValueChange = (childValue, parentValue) => {
    changeCheckboxChildValue(childValue, step, parentValue);
  };

  const handleCustomValueChange = (event) => {
    changeCustomValue(event.target.value, step);
  };

  const handleCheckboxQuantityChange = (event) => {
    const optionId = event.target.name;
    const quantity = event.target.value < 1 ? 1 : event.target.value;
    changeCheckboxQuantity(optionId, step, quantity);
  };

  const handleCheckboxValueChange = (event) => {
    const optionId = event.target.name;
    const checked = event.target.checked;
    setUncheckedLast(!checked);

    toggleCheckboxValue(optionId, step, checked);
  };

  const handleCommentValueChange = (event) => {
    changeCommentValue(event.target.value, step);
  };

  if (!isAllowedStep) {
    return (
      <Paper sx={{ p: 3, mb: 1 }} elevation={3}>
        <article>
          <Typography variant='h5'>
            Current step is not allowed. Select another step from the navigation
            please.
          </Typography>
        </article>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 3, mb: 1 }} elevation={3}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2 }}>
          <Typography gutterBottom variant='h5'>
            {stepData.name}
          </Typography>
          {stepData.subtitle && (
            <Typography
              sx={{ whiteSpace: 'pre-line' }}
              gutterBottom
              variant='body2'
              color='textSecondary'
              dangerouslySetInnerHTML={{ __html: stepData.subtitle }}
            />
          )}
        </Box>
        <Box sx={{ display: 'flex', minHeight: 471 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: 365 }}>
            {stepData.options.type === 'customText' && (
              <SalesConfiguratorRadioButtonsWithCustomTextOption
                value={stepData.value?.parentValue}
                options={stepData.options.values}
                handleValueChange={handleParentValueChange}
                handleCustomChange={handleCustomValueChange}
                selectedValues={selectedValues}
                customValue={customValues[step] ?? ''}
              />
            )}

            {stepData.options.type === 'radio' && (
              <SalesConfiguratorRadioButtons
                value={stepData.value}
                options={stepData.options.values}
                selectedValues={selectedValues}
                selectedChildValues={selectedChildValues}
                handleParentValueChange={handleParentValueChange}
                handleChildValueChange={handleChildValueChange}
              />
            )}

            {stepData.options.type === 'checkbox' && (
              <SalesConfiguratorCheckboxes
                value={stepData.value}
                options={stepData.options.values}
                handleValueChange={handleCheckboxValueChange}
                handleCheckboxChildValueChange={handleCheckboxChildValueChange}
                handleCheckboxQuantityChange={handleCheckboxQuantityChange}
              />
            )}
            {stepData.commentField && (
              <Box sx={{ mt: 4 }}>
                <SalesConfiguratorCommentField
                  commentField={stepData.commentField}
                  changeCommentValue={handleCommentValueChange}
                  commentValue={commentValues[step] ?? ''}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {selectedOption?.image && !uncheckedLast && (
              <Box sx={{ ml: 10, mt: 3, mb: 3 }}>
                <ValueImage image={selectedOption.image} />
                {selectedOption?.specifications && (
                  <SalesConfiguratorSpecifications
                    specifications={selectedOption.specifications}
                  />
                )}
              </Box>
            )}
            {selectedOption?.specifications &&
              !selectedOption?.image &&
              !uncheckedLast && (
                <Box sx={{ ml: 10, display: 'flex', maxWidth: 600 }}>
                  <SalesConfiguratorSpecifications
                    specifications={selectedOption.specifications}
                  />
                </Box>
              )}
          </Box>
        </Box>
        {stepData.images && stepData.images.length > 0 && (
          <SalesConfiguratorImages images={stepData.images} />
        )}
      </Box>
      {stepData.description && (
        <Box sx={{ mt: 6 }}>
          <SalesConfiguratorDescription
            title={stepData.description.title}
            value={stepData.description.value}
          />
        </Box>
      )}
      {stepData.textBasedOnPreviousSelection &&
        stepData.textBasedOnPreviousSelection.selection.some((selection) =>
          selectedValues.includes(selection)
        ) && (
          <Box>
            <Typography variant='body2' color='textSecondary'>
              {stepData.textBasedOnPreviousSelection.text}
            </Typography>
          </Box>
        )}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <SalesConfiguratorNextButton />
      </Box>
    </Paper>
  );
};

export default SalesConfiguratorStep;
