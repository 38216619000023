import React from "react";
import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";
import ChildRadioButton from "components/ChildRadioButton";
import usePriceStore from "app/stores/usePriceStore";
import SalesConfiguratorPrice from "features/salesConfigurator/components/SalesConfiguratorPrice";

const RadioButtons = ({
  value,
  options,
  selectedValues,
  selectedChildValues,
  handleParentValueChange,
  handleChildValueChange,
}) => {
  const prices = usePriceStore((state) => state.prices);

  const onParentValueChange = (event) => {
    handleParentValueChange(event.target.value);
  };

  const onChildValueChange = (event) => {
    handleChildValueChange(event.target.value);
  };

  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        value={value ? value.parentValue : null}
        onChange={onParentValueChange}
      >
        {options.map((option) => {
          const specificPriceChild = selectedChildValues.find(
            (selectedChildValue) =>
              option?.specificPrice?.includes(selectedChildValue)
          );

          const specificPriceParent = selectedValues.find((selectedValue) =>
            option?.specificPrice?.includes(selectedValue)
          );

          return (
            <React.Fragment key={option.id}>
              <Box
                sx={{ display: "flex", mt: 3, justifyContent: "space-between" }}
              >
                <FormControlLabel
                  value={`${option.id}`}
                  control={
                    <Radio size="small" sx={{ pt: 0 }} color="primary" />
                  }
                  sx={{ display: "flex", alignItems: "flex-start" }}
                  label={
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: 170,
                          width: "100%",
                        }}
                      >
                        <Typography variant="body2">{option.name}</Typography>
                        {option.link && (
                          <Link
                            underline="none"
                            target="_blank"
                            rel="noopener"
                            sx={{
                              fontSize: 14,
                              fontWeight: "medium",
                              ml: 1,
                              alignSelf: "flex-start",
                            }}
                            href={option.link}
                          >
                            Info
                          </Link>
                        )}
                      </Box>
                      {option.description && (
                        <Typography variant="body2" color="textSecondary">
                          {option.description}
                        </Typography>
                      )}
                    </>
                  }
                  disabled={
                    option.disabledBy &&
                    option.disabledBy.filter((id) =>
                      selectedValues.includes(id)
                    ).length > 0
                  }
                />
                {(prices.get(option.id) ||
                  (option.childOptions &&
                    prices.get(option.childOptions[0]?.id))) && (
                  <SalesConfiguratorPrice
                    isSelected={value?.parentValue === option.id}
                    hasChildOptions={!!option.childOptions}
                    price={
                      option.childOptions
                        ? value?.parentValue === option.id
                          ? Number(
                              prices.get(
                                specificPriceParent
                                  ? `${value.childValue}-${specificPriceParent}`
                                  : value.childValue
                              )
                            )
                          : Number(prices.get(option.childOptions[0].id))
                        : Number(
                            prices.get(
                              specificPriceChild
                                ? `${option.id}-${specificPriceChild}`
                                : option.id
                            )
                          )
                    }
                  />
                )}
              </Box>
              {value && (
                <Collapse in={selectedValues.includes(option.id)}>
                  <RadioGroup
                    value={value.childValue}
                    onChange={onChildValueChange}
                    sx={{ ml: 3 }}
                  >
                    {option.childOptions?.map((childOption) => (
                      <ChildRadioButton
                        key={childOption.id}
                        childOption={childOption}
                      />
                    ))}
                  </RadioGroup>
                </Collapse>
              )}
              {option.helperText && value?.parentValue === option.id && (
                <Typography variant="body2" sx={{ ml: 3 }}>
                  {option.helperText}
                </Typography>
              )}
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      specificPrice: PropTypes.array,
    })
  ),
  value: PropTypes.shape({
    parentValue: PropTypes.string,
    childValue: PropTypes.string,
  }),
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  selectedChildValues: PropTypes.arrayOf(PropTypes.string),
  handleParentValueChange: PropTypes.func.isRequired,
  handleChildValueChange: PropTypes.func.isRequired,
};

export default RadioButtons;
