import React, { useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";
import Box from "@mui/material/Box";

const SalesConfiguratorOrderForm = () => {
  const navigate = useNavigate();
  const { selectedNameValueSteps, totalPrice } = useContext(
    SalesConfiguratorContext
  );

  const [
    isProjectNumberOrPurchaseOrderIdDirty,
    setIsProjectNumberOrPurchaseOrderIdDirty,
  ] = useState(false);

  const [formValues, setFormValues] = useState({
    buyerFirstname: "",
    buyerLastname: "",
    buyerPhone: "",
    buyerEmail: "",
    company: "",
    clientName: "",
    projectNumber: "",
    purchaseOrderId: "",
    vatNumber: "",
    deliveryDate: "",
    additionalInfo: "",
  });

  const [buyerValidFields, setBuyerValidFields] = useState({
    buyerFirstname: null,
    buyerLastname: null,
    buyerPhone: null,
    buyerEmail: null,
  });

  const onFieldChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const validateRequiredField = ({ target }) => {
    const { name, value } = target;

    setBuyerValidFields({
      ...buyerValidFields,
      [name]: value.length > 0,
    });
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    if (!isValidBuyerForm) {
      return;
    }

    const data = {
      configurator: selectedNameValueSteps,
      orderDetails: formValues,
      totalPrice: totalPrice > 0 ? `${totalPrice} €` : null,
    };

    fetch(process.env.REACT_APP_LOGIC_APP_POST_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    }).then(() => {
      navigate("/salesConfigurator/order/done");
    });
  };

  const isValidBuyerForm = useMemo(() => {
    const isValid =
      formValues.buyerFirstname.length > 0 &&
      formValues.buyerLastname.length > 0 &&
      formValues.buyerPhone.length > 0 &&
      formValues.buyerEmail.length > 0;

    return isValid;
  }, [
    formValues.buyerEmail.length,
    formValues.buyerFirstname.length,
    formValues.buyerLastname.length,
    formValues.buyerPhone.length,
  ]);

  const isProjectNumberOrPurchaseOrderIdValid =
    formValues.projectNumber || formValues.purchaseOrderId;

  if (selectedNameValueSteps.length === 0) {
    return (
      <Paper sx={{ p: 3 }} elevation={3}>
        <Typography variant="h5">
          There is no items in the order. Please go back to configurator.
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper sx={{ p: 3 }} elevation={3}>
      <Typography variant="h5" gutterBottom>
        Inquiry details
      </Typography>

      <Divider sx={{ my: 3 }} />

      <form onSubmit={onFormSubmit} noValidate autoComplete="off">
        <section>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Customer information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 2,
              rowGap: 2,
            }}
          >
            <TextField
              name="buyerFirstname"
              label="First name"
              variant="outlined"
              value={formValues.buyerFirstname}
              onChange={onFieldChange}
              required
              onBlur={validateRequiredField}
              error={buyerValidFields["buyerFirstname"] === false}
            />
            <TextField
              name="buyerLastname"
              label="Last name"
              variant="outlined"
              value={formValues.buyerLastname}
              onChange={onFieldChange}
              required
              onBlur={validateRequiredField}
              error={buyerValidFields["buyerLastname"] === false}
            />
            <TextField
              name="buyerPhone"
              label="Phone"
              variant="outlined"
              value={formValues.buyerPhone}
              onChange={onFieldChange}
              required
              onBlur={validateRequiredField}
              error={buyerValidFields["buyerPhone"] === false}
            />
            <TextField
              name="buyerEmail"
              label="Email"
              variant="outlined"
              value={formValues.buyerEmail}
              onChange={onFieldChange}
              required
              onBlur={validateRequiredField}
              error={buyerValidFields["buyerEmail"] === false}
            />
            <TextField
              name="company"
              label="Company"
              variant="outlined"
              sx={{ gridColumn: "1 / span 2" }}
              value={formValues.company}
              onChange={onFieldChange}
            />
          </Box>
        </section>

        <Divider sx={{ my: 3 }} />
        <section>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Other information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 2,
              rowGap: 2,
            }}
          >
            <TextField
              name="clientName"
              label="Client name"
              variant="outlined"
              sx={{ gridColumn: "1 / span 2" }}
              onChange={onFieldChange}
            />

            <TextField
              required
              name="projectNumber"
              label="Project number"
              variant="outlined"
              onChange={onFieldChange}
              onBlur={() => setIsProjectNumberOrPurchaseOrderIdDirty(true)}
              helperText="Project number or purchase order id is required"
              error={
                isProjectNumberOrPurchaseOrderIdDirty &&
                !isProjectNumberOrPurchaseOrderIdValid
              }
            />
            <TextField
              required
              name="purchaseOrderId"
              label="Purchase order id"
              variant="outlined"
              onChange={onFieldChange}
              onBlur={() => setIsProjectNumberOrPurchaseOrderIdDirty(true)}
              error={
                isProjectNumberOrPurchaseOrderIdDirty &&
                !isProjectNumberOrPurchaseOrderIdValid
              }
              helperText="Project number or purchase order id is required"
            />

            <TextField
              name="vatNumber"
              label="VAT-number"
              variant="outlined"
              onChange={onFieldChange}
            />
            <TextField
              name="deliveryDate"
              label="Delivery date"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={onFieldChange}
            />
          </Box>
        </section>
        <Divider sx={{ my: 3 }} />

        <section>
          <TextField
            name="additionalInfo"
            label="Additional info"
            variant="outlined"
            onChange={onFieldChange}
            fullWidth
            multiline
            rows={6}
          />
        </section>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              !isValidBuyerForm || !isProjectNumberOrPurchaseOrderIdValid
            }
          >
            Submit for review
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default SalesConfiguratorOrderForm;
