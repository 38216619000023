import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SalesConfigurator from "features/salesConfigurator/pages/SalesConfigurator";
import SalesConfiguratorCheckout from "features/salesConfigurator/pages/SalesConfiguratorCheckout";
import SalesConfiguratorDone from "features/salesConfigurator/pages/SalesConfiguratorDone";
import SalesConfiguratorStart from 'features/salesConfigurator/pages/SalesConfiguratorStart'
import { SalesConfiguratorProvider } from "features/salesConfigurator/state/SalesConfiguratorContext";
import UnauthenticatedApp from "UnauthenticatedApp";
import usePasswordAuth from "hooks/usePasswordAuth";
import esteriLogoBlue from "assets/esteri_logo.jpg";
import esteriLogoWhite from "assets/esteri_logo_white.png";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import usePriceStore from 'app/stores/usePriceStore'
import CircularProgress from "@mui/material/CircularProgress";
import SalesConfiguratorError from 'features/salesConfigurator/pages/SalesConfiguratorError'


const App = () => {
  const { isAuth, login, isLoading: isPasswordLoading, isError: isPasswordError } = usePasswordAuth();
  const theme = useTheme();
  const isLoading = usePriceStore((state) => state.isLoading);
  const isErrorLoadingPrices = usePriceStore(state => state.isError)

  const fetchData = usePriceStore((state) => state.fetch);

  React.useEffect(() => {
    if (isAuth) {
      fetchData()
    }
  }, [fetchData, isAuth])


  if (!isAuth) {
    return (isPasswordError ? <SalesConfiguratorError /> : <UnauthenticatedApp isPasswordLoading={isPasswordLoading} login={login} />)
  }

  return (
    <>
      <AppBar
        sx={{ backgroundColor: theme.palette.client.primary }}
        position="fixed"
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            component="img"
            sx={{ height: theme.breakpoints.up("sm") ? 65 : 56 }}
            src={esteriLogoBlue}
            alt="Esteri blue logo"
          />
          <Box
            component="img"
            sx={{ height: 48 }}
            src={esteriLogoWhite}
            alt="Esteri white logo"
          />
        </Toolbar>
      </AppBar>
      <Box sx={(theme) => theme.mixins.toolbar} />
      {isLoading || isPasswordLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Box> : isErrorLoadingPrices ? <SalesConfiguratorError /> :
        <SalesConfiguratorProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/salesConfigurator" replace />} />
            <Route
              path="/salesConfigurator"
              element={<SalesConfiguratorStart />}
            />
            <Route
              path="/salesConfigurator/order/checkout"
              element={<SalesConfiguratorCheckout />}
            />
            <Route
              path="/salesConfigurator/:step"
              element={<SalesConfigurator />}
            />
            <Route
              path="/salesConfigurator/order/done"
              element={<SalesConfiguratorDone />}
            />
          </Routes>
        </SalesConfiguratorProvider>
      }
    </>
  );
};

export default App;
