import React, { useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";
import Box from "@mui/material/Box";

const SalesConfiguratorDone = () => {
  const { resetConfigurator } = useContext(SalesConfiguratorContext);

  useEffect(() => {
    resetConfigurator();
  }, [resetConfigurator]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Paper elevation={3} sx={{ p: 3, minWidth: 350 }}>
        <Typography align="center" variant="h5" gutterBottom>
          Thank you
        </Typography>
        <Typography align="center" color="textSecondary">
          You will receive an email with the inquiry details.
        </Typography>
      </Paper>
    </Box>
  );
};

export default SalesConfiguratorDone;
