import React, { useContext } from "react";
import { stepOptions } from "constants/salesConfiguratorData";
import Timeline from "@mui/lab/Timeline";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";
import SalesConfiguratorNavigationTimeLineItem from "features/salesConfigurator/components/SalesConfiguratorNavigationTimeLineItem";

const SalesConfiguratorNavigation = () => {
  const { selectedValues } = useContext(SalesConfiguratorContext);

  return (
    <nav>
      <Timeline sx={{ pt: 0, mt: 0 }} align="left">
        {stepOptions.map((step, index) => {
          const isLastItem = stepOptions.length === index + 1;
          const isDisabled =
            step.requires.length > 0 &&
            !step.requires.some((requirement) =>
              selectedValues.includes(requirement)
            );
          return (
            <SalesConfiguratorNavigationTimeLineItem
              key={step.id}
              id={step.id}
              name={step.name}
              isLastItem={isLastItem}
              isDisabled={!!isDisabled}
            />
          );
        })}
      </Timeline>
    </nav>
  );
};

export default SalesConfiguratorNavigation;
