import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const SalesConfiguratorSpecifications = ({ specifications }) => {
  return (
    <Box sx={{ display: "flex" }}>
      {specifications.map((specification) => (
        <Box sx={{ mb: 1, mr: 4 }} key={specification.title}>
          <Typography
            variant="body2"
            sx={{ whiteSpace: "nowrap", fontWeight: "medium" }}
          >
            {specification.title}
          </Typography>

          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: specification.value }}
          />
        </Box>
      ))}
    </Box>
  );
};

SalesConfiguratorSpecifications.propTypes = {
  specifications: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
};

export default SalesConfiguratorSpecifications;
