import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const SalesConfiguratorImages = ({ images }) => {
  const [showImage, setShowImage] = useState("");

  if (!images) {
    return null;
  }

  const handleThumbnailClick = (imgSrc) => {
    setShowImage(imgSrc);
  };

  return (
    <>
      <Box sx={{ mt: 4, display: "flex" }}>
        {images.map((image) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 309,
              cursor: "pointer",
              mr: 4,
            }}
            key={image.src}
            onClick={() => handleThumbnailClick(image.src)}
          >
            <div>
              <Box
                component="img"
                sx={{ maxHeight: 284 }}
                src={image.thumbnail}
                alt={image.title}
              />
            </div>
            <Typography variant="caption">{image.title}</Typography>
          </Box>
        ))}
      </Box>

      <Modal
        open={!!showImage}
        onClose={() => setShowImage("")}
        style={{ height: "100%" }}
      >
        <Box
          component="img"
          src={showImage}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: `translate(-${50}%, -${50}%)`,
          }}
          alt="Modal image"
        />
      </Modal>
    </>
  );
};

SalesConfiguratorImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

export default SalesConfiguratorImages;
