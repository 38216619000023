import * as React from "react";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

const SalesConfiguratorCommentField = ({
  commentField,
  changeCommentValue,
  commentValue,
}) => {
  return (
    <TextField
      label={commentField}
      variant="outlined"
      onChange={changeCommentValue}
      value={commentValue}
      multiline
      fullWidth
    />
  );
};

export default SalesConfiguratorCommentField;

SalesConfiguratorCommentField.propTypes = {
  commentField: PropTypes.string,
  changeCommentValue: PropTypes.func,
  commentValue: PropTypes.string,
};
