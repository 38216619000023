import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SalesConfiguratorTimeLineLink = ({ isDisabled, id, children }) => {
  if (isDisabled) {
    return <div>{children}</div>;
  }

  return (
    <Link to={`/salesConfigurator/${id}`} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  );
};

SalesConfiguratorTimeLineLink.propTypes = {
  isDisabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SalesConfiguratorTimeLineLink;
