export const stepOptions = [
  {
    id: "corePump",
    name: "Core pump",
    subtitle:
      "Core pump includes pump volute with mounting surfaces, impeller, inducer, shaft seal, bearing housing, blank plates and testing. For FPH models, additional high pressure stage components included.",
    value: null,
    description: {
      title: "Hydraulic driven pumps",
      value:
        `All ESTERI pump models are available with hydraulic drive. In hydraulic driven pump, the bearing housing is replaced with hydraulic motor. ESTERI uses Parker® hydraulic motors mounted directly to the pump body. For hydraulic driven pump options, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Ester Group sales.</a>`,
    },
    requires: [],
    images: [],
    options: {
      type: "radio",
      values: [
        {
          id: "corePump-1",
          name: "ESTERI 200 (FPN 10-2000)",
          description: "Flow rate 2500lpm @10Bar",
          link: "https://www.fwp.fi/product/esteri-200/?lang=en&v=f0aa03aaca95",
          image: {
            src: "/optionImages/corePump/Esteri_200_300.JPG",
            thumbnail: "/optionImages/corePump/Esteri_200_300.JPG",
            title: null,
          },
          disabledBy: null,
          childOptions: [
            {
              id: "corePump-1-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-1-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-2",
          name: "ESTERI 300 (FPN 10-3000)",
          description: "Flow rate 3500lpm @10Bar",
          link: "https://www.fwp.fi/product/esteri-300/?lang=en&v=f0aa03aaca95",
          image: {
            src: "/optionImages/corePump/Esteri_200_300.JPG",
            thumbnail: "/optionImages/corePump/Esteri_200_300.JPG",
            title: null,
          },
          disabledBy: null,
          childOptions: [
            {
              id: "corePump-2-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-2-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-3",
          name: "ESTERI 400 (FPN 10-4000)",
          description: "Flow rate 5000lpm @10Bar",
          link: "https://www.fwp.fi/product/esteri-400/?lang=en&v=f0aa03aaca95",
          disabledBy: null,
          image: {
            src: "/optionImages/corePump/ESTERI_400_600.JPG",
            thumbnail: "/optionImages/corePump/ESTERI_400_600.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "corePump-3-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-3-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-4",
          name: "ESTERI 600 (FPN 10-6000)",
          description: "Flow rate 6500lpm @10Bar",
          link: "https://www.fwp.fi/product/esteri-600/?lang=en&v=f0aa03aaca95",
          disabledBy: null,
          image: {
            src: "/optionImages/corePump/ESTERI_400_600.JPG",
            thumbnail: "/optionImages/corePump/ESTERI_400_600.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "corePump-4-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-4-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-5",
          name: "ESTERI 1000",
          description: "Flow rate 10000lpm @10Bar",
          link:
            "https://www.fwp.fi/product/esteri-1000/?lang=en&v=f0aa03aaca95",
          disabledBy: null,
          image: {
            src: "/optionImages/corePump/ESTERI_1000.JPG",
            thumbnail: "/optionImages/corePump/ESTERI_1000.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "corePump-5-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-5-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-6",
          name: "ESTERI 200/25 (FPN 10-2000/FPH 40-250)",
          description: "Flow rate 2500lpm @10Bar / 400lpm @40Bar",
          disabledBy: null,
          image: {
            src: "/optionImages/corePump/ESTERI_200_300_25.JPG",
            thumbnail: "/optionImages/corePump/ESTERI_200_300_25.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "corePump-6-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-6-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "corePump-7",
          name: "ESTERI 300/25 (FPN 10-3000/FPH 40-250)",
          description: "Flow rate 3500lpm @10Bar / 400lpm @40Bar",
          link:
            "https://www.fwp.fi/product/esteri-300-25/?lang=en&v=f0aa03aaca95",
          disabledBy: null,
          image: {
            src: "/optionImages/corePump/ESTERI_200_300_25.JPG",
            thumbnail: "/optionImages/corePump/ESTERI_200_300_25.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "corePump-7-child-1",
              name: "Aluminium",
            },
            {
              id: "corePump-7-child-2",
              name: "Bronze",
            },
          ],
        },
      ],
    },
  },
  {
    id: "rotation",
    name: "Drive shaft rotation",
    subtitle:
      "Choose the desired <u>drive shaft rotation</u>. In ESTERI fire pumps the pump rotation is determined when looking at the inlet of the pump.",
    value: null,
    requires: [],
    options: {
      type: "radio",
      values: [
        {
          id: "rotation-1",
          name: "Clockwise",
          disabledBy: null,
          image: {
            src: "/optionImages/rotation/Clockwise.JPG",
            thumbnail: "/optionImages/rotation/Clockwise.JPG",
            title: null,
          },
        },
        {
          id: "rotation-2",
          name: "Counterclockwise",
          disabledBy: null,
          image: {
            src: "/optionImages/rotation/Counter_clockwise.JPG",
            thumbnail: "/optionImages/rotation/Counter_clockwise.JPG",
            title: null,
          },
        },
      ],
    },
  },

  {
    id: "gearbox",
    name: "Gearbox",
    subtitle:
      "ESTERI gearbox is made of aluminium. The pump will be equipped with bearing housing, if gearbox is not needed.",
    value: null,
    requires: [],
    commentField: "Engine and PTO data",
    options: {
      type: "radio",
      values: [
        {
          id: "gearbox-1",
          name: "No gearbox",
          disabledBy: null,
          image: {
            src: "/optionImages/gearbox/No_gearbox.JPG",
            thumbnail: "/optionImages/gearbox/No_gearbox.JPG",
            title: null,
          },
        },
        {
          id: "gearbox-2",
          name: "Gearbox, with ratio",
          disabledBy: ["corePump-5"],
          image: {
            src: "/optionImages/gearbox/Gearbox.JPG",
            thumbnail: "/optionImages/gearbox/Gearbox.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "gearbox-2-child-1",
              name: "1:1,17",
            },
            {
              id: "gearbox-2-child-2",
              name: "1:1,37",
            },
            {
              id: "gearbox-2-child-3",
              name: "1:1,53",
            },
            {
              id: "gearbox-2-child-4",
              name: "1:1,71",
            },
            {
              id: "gearbox-2-child-5",
              name: "1:1,92",
            },
            {
              id: "gearbox-2-child-6",
              name: "1:2,16",
            },
            {
              id: "gearbox-2-child-7",
              name: "1:2,45",
            },
            {
              id: "gearbox-2-child-8",
              name: "Unknown",
            },
          ],
        },
        {
          id: "gearbox-3",
          name: "Gearbox, with ratio (Esteri 1000)",
          image: {
            src: "/optionImages/gearbox/Gearbox.JPG",
            thumbnail: "/optionImages/gearbox/Gearbox.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "gearbox-3-child-1",
              name: "1:1,14",
            },
            {
              id: "gearbox-3-child-2",
              name: "1:1,34",
            },
            {
              id: "gearbox-3-child-3",
              name: "1:1,50",
            },
            {
              id: "gearbox-3-child-4",
              name: "1:1,68",
            },
            {
              id: "gearbox-3-child-5",
              name: "Unknown",
            },
          ],
        },
      ],
    },
  },

  {
    id: "gearboxPosition",
    name: "Gearbox position",
    subtitle:
      "Choose the desired gearbox position. Position is determined when looking from gearbox towards the pump. Note that if pump is equipped with piston primer, only position 0° (up) is available.",
    value: null,
    requires: ["gearbox-2", "gearbox-3"],
    textBasedOnPreviousSelection: { selection: ["corePump-6", "corePump-7"], text: "If another position is required, please contact Esteri Group sales." },
    options: {
      type: "radio",
      values: [
        {
          id: "gearboxPosition-1",
          name: "0⁰ (up) (as 12.00)",
          disabledBy: ["corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/gearboxPosition/0.JPG",
            thumbnail: "/optionImages/gearboxPosition/0.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-2",
          name: "45⁰ (as 13.30)",
          disabledBy: ["corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/gearboxPosition/45.JPG",
            thumbnail: "/optionImages/gearboxPosition/45.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-3",
          name: "90⁰ (right) (as 15.00)",
          disabledBy: null,
          image: {
            src: "/optionImages/gearboxPosition/90.JPG",
            thumbnail: "/optionImages/gearboxPosition/90.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-4",
          name: "135⁰ (as 16.30)",
          disabledBy: ["corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/gearboxPosition/135.JPG",
            thumbnail: "/optionImages/gearboxPosition/135.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-5",
          name: "180⁰ (down) (as 18.00)",
          disabledBy: null,
          image: {
            src: "/optionImages/gearboxPosition/180.JPG",
            thumbnail: "/optionImages/gearboxPosition/180.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-6",
          name: "225⁰ (as 19.30)",
          disabledBy: ["corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/gearboxPosition/225.JPG",
            thumbnail: "/optionImages/gearboxPosition/225.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-7",
          name: "270⁰ (left) (as 21.00)",
          disabledBy: null,
          image: {
            src: "/optionImages/gearboxPosition/270.JPG",
            thumbnail: "/optionImages/gearboxPosition/270.JPG",
            title: null,
          },
        },
        {
          id: "gearboxPosition-8",
          name: "315⁰ (as 19:00)",
          disabledBy: ["corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/gearboxPosition/315.JPG",
            thumbnail: "/optionImages/gearboxPosition/315.JPG",
            title: null,
          },
        },
      ],
    },
  },

  {
    id: "pumpDriveFlange",
    name: "Pump drive flange",
    value: null,
    description: { value: `Threads/clearances as a reference, to be agreed with the customer. For custom flange types, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    requires: [],
    options: {
      type: "radio",
      values: [
        {
          id: "pumpDriveFlange-1",
          name: "No flange",
          disabledBy: null,
        },
        {
          id: "pumpDriveFlange-2",
          name: "31SAE",
          disabledBy: ["corePump-3", "corePump-4", "corePump-5"],
          image: {
            src: "/optionImages/pumpDriveFlange/SAE1300.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/SAE1300.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-3",
          name: "DIN100",
          disabledBy: ["corePump-3", "corePump-4", "corePump-5"],
          image: {
            src: "/optionImages/pumpDriveFlange/DIN100.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/DIN100.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-4",
          name: "41SAE",
          disabledBy: ["corePump-5"],
          image: {
            src: "/optionImages/pumpDriveFlange/SAE1400.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/SAE1400.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-5",
          name: "DIN120",
          disabledBy: ["corePump-5"],
          image: {
            src: "/optionImages/pumpDriveFlange/DIN120.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/DIN120.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-6",
          name: "51SAE",
          disabledBy: ["corePump-1", "corePump-2", "corePump-3", "corePump-4", "corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/pumpDriveFlange/SAE1510.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/SAE1510.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-7",
          name: "DIN150",
          disabledBy: ["corePump-1", "corePump-2", "corePump-3", "corePump-4", "corePump-6", "corePump-7"],
          image: {
            src: "/optionImages/pumpDriveFlange/DIN150.JPG",
            thumbnail: "/optionImages/pumpDriveFlange/DIN150.JPG",
            title: null,
          },
        },
        {
          id: "pumpDriveFlange-8",
          name: "s21 (Löbro)",
          disabledBy: ["corePump-5"]
        },
        {
          id: "pumpDriveFlange-9",
          name: "Other",
        },
      ],
    },
  },

  {
    id: "pumpInlet",
    name: "Pump inlet",
    subtitle:
      "ESTERI pumps are delivered with two basic inlet cover types, short cover and long cover.\n\nBoth inlet types have BSP thread connection as a standard. Short cover is usually used with mid mounted pumps and long cover type usually used in rear mounted versions. Long cover has additional connections for tank pipe, collecting head etc.",
    value: null,
    requires: [],
    options: {
      type: "radio",
      values: [
        {
          id: "pumpInlet-1",
          name: "Short inlet cover",
          disabledBy: null,
          image: {
            src: "/optionImages/pumpInlet/Short_inlet_cover.JPG",
            thumbnail: "/optionImages/pumpInlet/Short_inlet_cover.JPG",
            title: null,
          },
          specificPrice: [
            "corePump-1-child-1",
            "corePump-1-child-2",
            "corePump-2-child-1",
            "corePump-2-child-2",
            "corePump-3-child-1",
            "corePump-3-child-2",
            "corePump-4-child-1",
            "corePump-4-child-2",
            "corePump-5-child-1",
            "corePump-5-child-2",
            "corePump-6-child-1",
            "corePump-6-child-2",
            "corePump-7-child-1",
            "corePump-7-child-2",
          ]
        },
        {
          id: "pumpInlet-2",
          name: "Long inlet cover",
          disabledBy: null,
          image: {
            src: "/optionImages/pumpInlet/Long_inlet_cover.JPG",
            thumbnail: "/optionImages/pumpInlet/Long_inlet_cover.JPG",
            title: null,
          },
          specificPrice: [
            "corePump-1-child-1",
            "corePump-1-child-2",
            "corePump-2-child-1",
            "corePump-2-child-2",
            "corePump-3-child-1",
            "corePump-3-child-2",
            "corePump-4-child-1",
            "corePump-4-child-2",
            "corePump-5-child-1",
            "corePump-5-child-2",
            "corePump-6-child-1",
            "corePump-6-child-2",
            "corePump-7-child-1",
            "corePump-7-child-2",
          ]
        },
      ],
    },
  },

  {
    id: "suctionInletMid",
    name: "Suction inlet (short cover)",
    description: {
      value:
        `ESTERI pump inlets can be equipped with all major hose coupling types. Please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a> for more info.\n\nFor customized inlet options, contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.`,
    },
    value: null,
    requires: ["pumpInlet-1"],
    options: {
      type: "radio",
      values: [
        {
          id: "suctionInletMid-1",
          name: "DN100 flange",
          image: {
            src: "/optionImages/suctionInlet/Flange_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/Flange_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-1-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletMid-1-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletMid-2",
          name: "4” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletMid-3",
          name: "4” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletMid-4",
          name: "Butterfly valve DN100",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            thumbnail:
              "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-4-child-1",
              name: "With lever",
            },
            {
              id: "suctionInletMid-4-child-2",
              name: "With pneumatic actuator",
            },
          ],
        },
        {
          id: "suctionInletMid-5",
          name: "DN150 (standard) flange",
          image: {
            src: "/optionImages/suctionInlet/Flange_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/Flange_inlet.JPG",
            title: null,
          },
          disabledBy: ["corePump-1", "corePump-4", "corePump-5", "corePump-6"],
          childOptions: [
            {
              id: "suctionInletMid-5-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletMid-5-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletMid-6",
          name: "DN125 (optional) flange",
          image: {
            src: "/optionImages/suctionInlet/Flange_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/Flange_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: [
            {
              id: "suctionInletMid-6-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletMid-6-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletMid-7",
          name: "6” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: ["corePump-1", "corePump-4", "corePump-5", "corePump-6"],
          childOptions: null,
        },
        {
          id: "suctionInletMid-8",
          name: "6” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            title: null,
          },
          disabledBy: ["corePump-1", "corePump-4", "corePump-5", "corePump-6"],
          childOptions: null,
        },
        {
          id: "suctionInletMid-9",
          name: "5½” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-10",
          name: "5½” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-11",
          name: "5” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-12",
          name: "5” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSRT_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-13",
          name: "Butterfly valve DN150",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            thumbnail:
              "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            title: null,
          },
          disabledBy: ["corePump-1", "corePump-4", "corePump-5", "corePump-6"],
          childOptions: [
            {
              id: "suctionInletMid-13-child-1",
              name: "With lever",
            },
            {
              id: "suctionInletMid-13-child-2",
              name: "With pneumatic actuator",
            },
          ],
        },
        {
          id: "suctionInletMid-14",
          name: "Butterfly valve DN125",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            thumbnail:
              "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
          childOptions: [
            {
              id: "suctionInletMid-14-child-1",
              name: "With lever",
            },
            {
              id: "suctionInletMid-14-child-2",
              name: "With pneumatic actuator",
            },
          ],
        },
        {
          id: "suctionInletMid-15",
          name: "DN200 flange",
          image: {
            src: "/optionImages/suctionInlet/Flange_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/Flange_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-15-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletMid-15-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletMid-16",
          name: "8” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-17",
          name: "Butterfly valve DN200",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            thumbnail:
              "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-17-child-1",
              name: "With lever",
            },
            {
              id: "suctionInletMid-17-child-2",
              name: "With pneumatic actuator",
            },
          ],
        },
        {
          id: "suctionInletMid-18",
          name: "DN250 flange",
          image: {
            src: "/optionImages/suctionInlet/Flange_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/Flange_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-18-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletMid-18-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletMid-19",
          name: "10” BSP",
          image: {
            src: "/optionImages/suctionInlet/BSP_inlet.JPG",
            thumbnail: "/optionImages/suctionInlet/BSP_inlet.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: null,
        },
        {
          id: "suctionInletMid-20",
          name: "Butterfly valve DN250",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            thumbnail:
              "/optionImages/suctionInlet/Inlet_w_butterfly_valve.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-6",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletMid-20-child-1",
              name: "With lever",
            },
            {
              id: "suctionInletMid-20-child-2",
              name: "With pneumatic actuator",
            },
          ],
        },
      ],
    },
  },

  {
    id: "suctionInletRear",
    name: "Suction inlet (long cover)",
    subtitle: `For long inlet ESTERI pump, we provide wide variety of options to the inlet.\n\nIn addition to basic suction inlet options, the long inlet type pump cover can be equipped with tank pipe curve, for easy connection to the tank tubing. Also, a collecting head for external connection can be added to the cover.\n\nIn addition to basic options, ESTERI pumps can be equipped with fully customized inlet/suction tubing. In addition, ESTERI pump inlets can be equipped with all major hose coupling types. For additional information of customized options and couplings, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales.</a>`,
    value: null,
    description: { title: "Esteri 1000", value: `For long inlet for ESTERI 1000, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>` },
    requires: ["pumpInlet-2"],
    options: {
      type: "radio",
      values: [
        {
          id: "suctionInletRear-1",
          name: "DN100 flange",
          image: {
            src: "/optionImages/suctionInletRear/flange.JPG",
            thumbnail: "/optionImages/suctionInletRear/flange.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
          childOptions: [
            {
              id: "suctionInletRear-1-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-1-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "suctionInletRear-2",
          name: "4” BSP",
          image: {
            src: "/optionImages/suctionInletRear/BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSP.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-3",
          name: "4” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInletRear/BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSRT.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-4",
          name: "4” BSP, 15° suction crook",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-4-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-4-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-5",
          name: "4” BSP, 15° suction crook, w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-5-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-5-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-6",
          name: "4” BSRT, 15° suction crook (w. adapter piece)",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-6-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-6-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-7",
          name: "4” BSRT, 15° suction crook (w. adapter piece), w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-7-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-7-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "suctionInletRear-8",
          name: "DN125 flange",
          image: {
            src: "/optionImages/suctionInletRear/flange.JPG",
            thumbnail: "/optionImages/suctionInletRear/flange.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-8-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-8-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-9",
          name: "5½”” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInletRear/BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSRT.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-10",
          name: "5” BSP",
          image: {
            src: "/optionImages/suctionInletRear/BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSP.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-11",
          name: "5” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInletRear/BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSRT.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-12",
          name: "5” BSP, 15° suction crook",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-12-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-12-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-13",
          name: "5” BSP, 15° suction crook, w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-13-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-13-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-14",
          name: "5” (or 5½”) BSRT, 15° suction crook (w. adapter piece)",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-14-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-14-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-15",
          name: "5” (or 5½”) BSRT, 15° suction crook (w. adapter piece), w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-15-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-15-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "suctionInletRear-16",
          name: "DN150 flange",
          image: {
            src: "/optionImages/suctionInletRear/flange.JPG",
            thumbnail: "/optionImages/suctionInletRear/flange.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-16-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-16-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-17",
          name: "6” BSP",
          image: {
            src: "/optionImages/suctionInletRear/BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSP.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-18",
          name: "6” BSRT (w. adapter piece)",
          image: {
            src: "/optionImages/suctionInletRear/BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSRT.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-19",
          name: "6” BSP, 15° suction crook",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-19-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-19-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-20",
          name: "6” BSP, 15° suction crook, w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-20-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-20-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-21",
          name: "6” BSRT, 15° suction crook (w. adapter piece)",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-21-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-21-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-22",
          name: "6” BSRT, 15° suction crook (w. adapter piece), w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_BSRT_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-22-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-22-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-23",
          name: "DN200 flange",
          image: {
            src: "/optionImages/suctionInletRear/flange.JPG",
            thumbnail: "/optionImages/suctionInletRear/flange.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-23-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-23-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-24",
          name: "8” BSP",
          image: {
            src: "/optionImages/suctionInletRear/BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/BSP.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-25",
          name: "8” BSP, 15° suction crook",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_BSP.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-25-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-25-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
        {
          id: "suctionInletRear-26",
          name: "8” BSP, 15° suction crook, w. BF valve",
          helperText: "(Additional mounting flange required)",
          image: {
            src: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            thumbnail: "/optionImages/suctionInletRear/suction_crook_w_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "suctionInletRear-26-child-1",
              name: "Aluminium",
            },
            {
              id: "suctionInletRear-26-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7"
          ],
        },
      ],
    },
  },

  {
    id: "tankConnection",
    name: "Tank connection",
    subtitle: "Long inlet ESTERI pump cover can be equipped with a pipe curve for easy connection with the tank line.",
    description: { value: `For customized tank connection, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    value: null,
    requires: ["pumpInlet-2"],
    options: {
      type: "radio",
      values: [
        {
          id: "tankConnection-1",
          name: "No pipe (blank plate)",
          image: {
            src: "/optionImages/tankConnection/no_pipe.JPG",
            thumbnail: "/optionImages/tankConnection/no_pipe.JPG",
            title: null,
          },
          disabledBy: null,
        },
        {
          id: "tankConnection-2",
          name: "DN100 stainless steel pipe, air actuated BF valve, DN100 welding collar connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
            "suctionInletRear-1",
            "suctionInletRear-2",
            "suctionInletRear-3"
          ],
        },
        {
          id: "tankConnection-3",
          name: "DN100 stainless steel pipe, air actuated BF valve, DN100 hose spindle connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
            "suctionInletRear-1",
            "suctionInletRear-2",
            "suctionInletRear-3"
          ],
        },
        {
          id: "tankConnection-4",
          name: "DN100 stainless steel pipe, manual BF valve, DN100 welding collar connection",
          image: {
            src: "/optionImages/tankConnection/pipe_manual_bf_ welding_collar.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_manual_bf_ welding_collar.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-5",
          name: "DN100 stainless steel pipe, manual BF valve, DN100 hose spindle connection",
          image: {
            src: "/optionImages/tankConnection/pipe_manual_bf_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_manual_bf_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-6",
          name: "DN100 stainless steel pipe, DN100 flange",
          image: {
            src: "/optionImages/tankConnection/pipe_flange.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_flange.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-7",
          name: "DN100 stainless steel pipe, DN100 hose spindle",
          image: {
            src: "/optionImages/tankConnection/pipe_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-8",
          name: "DN125 aluminium pipe, air actuated BF valve, DN125 welding collar connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "tankConnection-9",
          name: "DN125 aluminium pipe, air actuated BF valve, DN125 hose spindle connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "tankConnection-10",
          name: "DN125 aluminium pipe, manual BF valve, DN125 welding collar connection",
          image: {
            src: "/optionImages/tankConnection/pipe_manual_bf_ welding_collar.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_manual_bf_ welding_collar.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "suctionInletRear-8",
            "suctionInletRear-9",
            "suctionInletRear-10",
            "suctionInletRear-11"
          ],
        },
        {
          id: "tankConnection-11",
          name: "DN125 aluminium pipe, manual BF valve, DN125 hose spindle connection",
          image: {
            src: "/optionImages/tankConnection/pipe_manual_bf_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_manual_bf_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "suctionInletRear-8",
            "suctionInletRear-9",
            "suctionInletRear-10",
            "suctionInletRear-11"
          ],
        },
        {
          id: "tankConnection-12",
          name: "DN125 aluminium pipe, DN125 flange",
          image: {
            src: "/optionImages/tankConnection/pipe_flange.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_flange.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "tankConnection-13",
          name: "DN125 aluminium pipe, DN125 hose spindle",
          image: {
            src: "/optionImages/tankConnection/pipe_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
          ],
        },
        {
          id: "tankConnection-14",
          name: "DN150 stainless steel pipe, air actuated BF valve, DN150 welding collar connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_welding_collar.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-15",
          name: "DN150 stainless steel pipe, air actuated BF valve, DN150 hose spindle connection",
          image: {
            src: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_actuated_bf_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-16",
          name: "DN150 stainless steel pipe, DN150 flange",
          image: {
            src: "/optionImages/tankConnection/pipe_flange.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_flange.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
        },
        {
          id: "tankConnection-17",
          name: "DN150 stainless steel pipe, DN150 hose spindle",
          image: {
            src: "/optionImages/tankConnection/pipe_spindle.JPG",
            thumbnail: "/optionImages/tankConnection/pipe_spindle.JPG",
            title: null,
          },
          disabledBy: [
            "corePump-1",
            "corePump-2",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
        },
      ],
    },
  },

  {
    id: "collectingHead",
    name: "Collecting head",
    subtitle: "ESTERI pumps can be equipped with an integrated aluminium collecting port for external hose feed line.",
    description: { value: `Collecting port can be equipped with all major fire coupling types. Also customized collecting heads are available. For these option, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    value: null,
    requires: ["pumpInlet-2"],
    options: {
      type: "radio",
      values: [
        {
          id: "collectingHead-1",
          name: "1 x DN65 ball valve",
          image: {
            src: "/optionImages/collectingHead/Collecting_head_1_x_DN65_ball_valve.JPG",
            thumbnail: "/optionImages/collectingHead/Collecting_head_1_x_DN65_ball_valve.JPG",
            title: null,
          },
          disabledBy: null,
        },
        {
          id: "collectingHead-2",
          name: "1 x DN80 ball valve",
          image: {
            src: "/optionImages/collectingHead/Collecting_head_1_x_DN65_ball_valve.JPG",
            thumbnail: "/optionImages/collectingHead/Collecting_head_1_x_DN65_ball_valve.JPG",
            title: null,
          },
          disabledBy: null,
        },
        {
          id: "collectingHead-3",
          name: "1 x DN65 gate valve",
          image: {
            src: "/optionImages/collectingHead/Collecting_head_1_x_DN65_gate_valve.JPG",
            thumbnail: "/optionImages/collectingHead/Collecting_head_1_x_DN65_gate_valve.JPG",
            title: null,
          },
          disabledBy: null,
        },
        {
          id: "collectingHead-4",
          name: "1 x DN80 gate valve",
          image: {
            src: "/optionImages/collectingHead/Collecting_head_1_x_DN65_gate_valve.JPG",
            thumbnail: "/optionImages/collectingHead/Collecting_head_1_x_DN65_gate_valve.JPG",
            title: null,
          },
          disabledBy: null,
        },
        {
          id: "collectingHead-5",
          name: "None",
          disabledBy: null,
        },
      ],
    },
  },

  {
    id: "voluteOutletManifold",
    name: "Volute outlet manifold",
    value: null,
    requires: [],
    description: { value: `ESTERI fire pumps can also be equipped with fully customized outlet configurations. Contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a> for more information.` },
    images: [
      {
        src: "/stepImages/example of manifold assembly.jpg",
        thumbnail: "/stepImages/example of manifold assembly.jpg",
        title: "Example of outlet manifold configuration. 4-way manifold, 3 x 25° outlet crook, 3 x DN65 ball valve, T-branch, 2½” BSP connection, Elbow 2½”, 2 x DN65 ball valve w. actuator.",
      }
    ],
    options: {
      type: "radio",
      values: [
        {
          id: "voluteOutletManifold-1",
          name: "No manifold (DIN standard flange at volute outlet)",
          disabledBy: null,
        },
        {
          id: "voluteOutletManifold-2",
          name: "Manifold 3-way",
          image: {
            src: "/optionImages/voluteOutletManifold/3-way.JPG",
            thumbnail: "/optionImages/voluteOutletManifold/3-way.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "voluteOutletManifold-2-child-1",
              name: "Aluminium",
            },
            {
              id: "voluteOutletManifold-2-child-2",
              name: "Bronze",
            },
          ],
          specificPrice: [
            "corePump-1",
            "corePump-2",
            "corePump-3",
            "corePump-4",
            "corePump-5",
            "corePump-6",
            "corePump-7",
          ],
          disabledBy: ["corePump-5"],
        },
        {
          id: "voluteOutletManifold-3",
          name: "Manifold 4-way",
          image: {
            src: "/optionImages/voluteOutletManifold/4-way.JPG",
            thumbnail: "/optionImages/voluteOutletManifold/4-way.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "voluteOutletManifold-3-child-1",
              name: "Aluminium",
            },
            {
              id: "voluteOutletManifold-3-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: ["corePump-3", "corePump-4", "corePump-5"],
        },
        {
          id: "voluteOutletManifold-4",
          name: "Manifold 5-way",
          image: {
            src: "/optionImages/voluteOutletManifold/5-way.JPG",
            thumbnail: "/optionImages/voluteOutletManifold/5-way.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "voluteOutletManifold-4-child-1",
              name: "Aluminium",
            },
            {
              id: "voluteOutletManifold-4-child-2",
              name: "Bronze",
            },
          ],
          disabledBy: ["corePump-3", "corePump-4", "corePump-5"],
        },
      ],
    },
  },

  {
    id: "manifoldExtensions",
    name: "Manifold extensions",
    description: { value: `ESTERI fire pumps can also be equipped with fully customized outlet configurations. Contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a> for more information.` },
    value: null,
    requires: ["voluteOutletManifold-2", "voluteOutletManifold-3", "voluteOutletManifold-4"],
    options: {
      type: "checkbox",
      values: [
        {
          id: "manifoldExtensions-1",
          name: "Elbow 2½” BSP connection, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/elbow.JPG",
            thumbnail: "/optionImages/manifoldExtensions/elbow.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-2",
          name: "Elbow 2½” BSP connection, bronze",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/elbow.JPG",
            thumbnail: "/optionImages/manifoldExtensions/elbow.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-3",
          name: "T-branch, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/t-branch.JPG",
            thumbnail: "/optionImages/manifoldExtensions/t-branch.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-4",
          name: "T-branch, bronze",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/t-branch.JPG",
            thumbnail: "/optionImages/manifoldExtensions/t-branch.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-5",
          name: "25° outlet crook, 2½” BSP connection, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/25crook.JPG",
            thumbnail: "/optionImages/manifoldExtensions/25crook.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-6",
          name: "25° outlet crook, 2½” BSP connection, bronze",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/25crook.JPG",
            thumbnail: "/optionImages/manifoldExtensions/25crook.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-7",
          name: "2½” BSP connection, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/BSP.JPG",
            thumbnail: "/optionImages/manifoldExtensions/BSP.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-8",
          name: "2½” BSP connection, bronze",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/BSP.JPG",
            thumbnail: "/optionImages/manifoldExtensions/BSP.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-9",
          name: "3” BSP connection, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/BSP.JPG",
            thumbnail: "/optionImages/manifoldExtensions/BSP.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-10",
          name: "3” BSP connection, bronze",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/BSP.JPG",
            thumbnail: "/optionImages/manifoldExtensions/BSP.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-11",
          name: "Welding connection, Ø76,1mm pipe, stainless steel",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/pipe.JPG",
            thumbnail: "/optionImages/manifoldExtensions/pipe.JPG",
            title: null,
          },
        },
        {
          id: "manifoldExtensions-12",
          name: "Welding connection, Ø88,9mm pipe, stainless steel",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/manifoldExtensions/pipe.JPG",
            thumbnail: "/optionImages/manifoldExtensions/pipe.JPG",
            title: null,
          },
        },
      ],
    },
  },

  {
    id: "deliveryValves",
    name: "Delivery valves",
    value: null,
    requires: [],
    description: { value: `In addition to listed options, all valves are available in sizes ranging from DN25-DN100. All valves can be equipped with all major fire hose coupling types. For these options, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    options: {
      type: "checkbox",
      values: [
        {
          id: "deliveryValve-1",
          name: "DN65 ball valve, 2½” BSP, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/Ball_valve.JPG",
            thumbnail: "/optionImages/deliveryValve/Ball_valve.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-2",
          name: "DN65 ball valve, 2½” BSP, aluminium, w. air actuator",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            thumbnail: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-3",
          name: "DN65 ball valve w. heating flange, 2½” BSP, aluminium",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/Ball_valve.JPG",
            thumbnail: "/optionImages/deliveryValve/Ball_valve.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-4",
          name: "DN65 ball valve w. heating flange, 2½” BSP, aluminium, w. air actuator",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            thumbnail: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-5",
          name: "DN65 ball valve grade1, 2½” BSP, stainless steel",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/Ball_valve.JPG",
            thumbnail: "/optionImages/deliveryValve/Ball_valve.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-6",
          name: "DN65 ball valve grade1, 2½” BSP, stainless steel, w. air actuator",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            thumbnail: "/optionImages/deliveryValve/ball_valve_w_actuator.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-7",
          name: "DN65 ball valve grade2, 2½” BSP, stainless steel",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/ball_valve_grade2.JPG",
            thumbnail: "/optionImages/deliveryValve/ball_valve_grade2.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-8",
          name: "DN65 gate valve, 2½” BSP, brass",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/gate_valve.JPG",
            thumbnail: "/optionImages/deliveryValve/gate_valve.JPG",
            title: null,
          },
        },
        {
          id: "deliveryValve-9",
          name: "Globe valve",
          disabledBy: null,
          hasQuantity: true,
          image: {
            src: "/optionImages/deliveryValve/globe_valve.JPG",
            thumbnail: "/optionImages/deliveryValve/globe_valve.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "deliveryValve-9-child-1",
              name: "Aluminium",
            },
            {
              id: "deliveryValve-9-child-2",
              name: "Bronze",
            },
          ],
        },
      ],
    },
  },

  {
    id: "primingOptions",
    name: "Priming options",
    value: null,
    requires: [],
    options: {
      type: "radio",
      values: [
        {
          id: "primingOptions-1",
          name: "No primer",
          disabledBy: null,
        },
        {
          id: "primingOptions-2",
          name: "Piston primer, aluminium (gearbox required)",
          disabledBy: ["gearbox-1"],
          image: {
            src: "/optionImages/primingOptions/Piston_primer.JPG",
            thumbnail: "/optionImages/primingOptions/Piston_primer.JPG",
            title: null,
          },
          specifications: [
            { title: "Piston primer", value: "Automatic primer fixed to the pump gearbox. When equipped with piston primer, only gearbox position 0° (up) is available. System has two (2) pistons, four (4) piston for ESTERI 1000, that are controlled by the fire pump shaft. Primer engagement/disengagement is controlled by the pump discharge pressure." },
          ],
        },
        {
          id: "primingOptions-3",
          name: "Compressed air primer",
          disabledBy: null,
          image: {
            src: "/optionImages/primingOptions/Compressed_air_primer.JPG",
            thumbnail: "/optionImages/primingOptions/Compressed_air_primer.JPG",
            title: null,
          },
          specifications: [
            { title: "Compressed air primer", value: "Compressed air primer that can be fixed to the fire pump, or delivered separately. Air for the primer is usually taken from the truck brake system compressor. Can be operated with manual lever valve, or with pneumatic valve. Can also be connected to the heating line of the pump." },
          ],
          childOptions: [
            {
              id: "primingOptions-3-child-1",
              name: "Aluminium",
            },
            {
              id: "primingOptions-3-child-2",
              name: "Bronze",
            },
          ],
        },
        {
          id: "primingOptions-4",
          name: "Electric primer 12V/24V",
          disabledBy: null,
          image: {
            src: "/optionImages/primingOptions/Electric_primer.JPG",
            thumbnail: "/optionImages/primingOptions/Electric_primer.JPG",
            title: null,
          },
          specifications: [
            { title: "Electric", value: "Electric rotary vane fluidless primer. Environmentally correct, does not discharge oil. Priming system includes push-pull valve with electrical switch. Can be equipped with auto-prime function. Can be fixed with the pump, or delivered separately." },
          ],
        },
      ],
    },
  },

  {
    id: "foamProportioning",
    name: "Foam proportioning system",
    value: null,
    description: { value: `ESTERI pumps can also be equipped with electronic proportioners and CAFS. For these options, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    requires: [],
    options: {
      type: "radio",
      values: [
        {
          id: "foamProportioning-1",
          name: "No foam system",
          disabledBy: null,
        },
        {
          id: "foamProportioning-2",
          name: "VEKO RTP system, brass",
          disabledBy: null,
          specifications: [
            { title: "VEKO RTP", value: "Around the pump system for up to 150 l/min foam liquid delivery. Proportioning is adjusted manually with control valve. Standard system includes eductor, eductor main valve and control valve." },
          ],
        },
        {
          id: "foamProportioning-4",
          name: "Esteri automatic RTP, aluminium",
          disabledBy: null,
          specifications: [
            { title: "ESTERI AUTOMATIC RTP", value: "Automatic around the pump foam mixing system, that adds the desired amount of foam liquid to the fire fighting water. The selected mixing ratio stays the same, no matter what amount of water is flowing through the pump. Up to three (3) foam proportion rates, with 1%, 3% and 6% options. Pressure manifold w. heating duct required. Not available with ESTERI 1000. Standard system includes eductor, eductor main valve, proportioner assembly and proportioning valve(s)." },
          ],
          image: {
            src: "/optionImages/foamProportioning/Automatic_RTP.JPG",
            thumbnail: "/optionImages/foamProportioning/Automatic_RTP.JPG",
            title: null,
          },
          childOptions: [
            {
              id: "foamProportioning-4-child-1",
              name: "One (1) mixing ratio, lever valves",
            },
            {
              id: "foamProportioning-4-child-2",
              name: "One (1) mixing ratio, pneumatic actuated valves",
            },
            {
              id: "foamProportioning-4-child-3",
              name: "Two (2) mixing ratios, lever valves",
            },
            {
              id: "foamProportioning-4-child-4",
              name: "Two (2) mixing ratios, pneumatic actuated valve",
            },
            {
              id: "foamProportioning-4-child-5",
              name: "Three (3) mixing ratios, lever valves",
            },
            {
              id: "foamProportioning-4-child-6",
              name: "Three (3) mixing ratios, pneumatic actuated valves",
            },
          ],
        },
        {
          id: "foamProportioning-5",
          name: "Esteri automatic RTP, bronze",
          disabledBy: null,
          specifications: [
            { title: "ESTERI AUTOMATIC RTP", value: "Automatic around the pump foam mixing system, that adds the desired amount of foam liquid to the fire fighting water. The selected mixing ratio stays the same, no matter what amount of water is flowing through the pump. Up to three (3) foam proportion rates, with 1%, 3% and 6% options. Pressure manifold w. heating duct required. Not available with ESTERI 1000. Standard system includes eductor, eductor main valve, proportioner assembly and proportioning valve(s)." },
          ],
          image: {
            src: "/optionImages/foamProportioning/Automatic_RTP.JPG",
            thumbnail: "/optionImages/foamProportioning/Automatic_RTP.JPG",
            title: null
          },
          childOptions: [
            {
              id: "foamProportioning-5-child-1",
              name: "One (1) mixing ratio, lever valves",
            },
            {
              id: "foamProportioning-5-child-2",
              name: "One (1) mixing ratio, pneumatic actuated valves",
            },
            {
              id: "foamProportioning-5-child-3",
              name: "Two (2) mixing ratios, lever valves",
            },
            {
              id: "foamProportioning-5-child-4",
              name: "Two (2) mixing ratios, pneumatic actuated valve",
            },
            {
              id: "foamProportioning-5-child-5",
              name: "Three (3) mixing ratios, lever valves",
            },
            {
              id: "foamProportioning-5-child-6",
              name: "Three (3) mixing ratios, pneumatic actuated valves",
            },
          ],
        },
      ],
    },
  },

  {
    id: "rtpFoamMixerPosition",
    name: "RTP foam mixer position",
    value: null,
    requires: ["foamProportioning-4", "foamProportioning-5"],
    options: {
      type: "radio",
      values: [
        {
          id: "rtpFoamMixerPosition-1",
          name: "Position 1",
          disabledBy: null,
          image: {
            src: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition1.JPG",
            thumbnail: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition1.JPG",
            title: null,
          },
        },
        {
          id: "rtpFoamMixerPosition-2",
          name: "Position 2",
          disabledBy: null,
          image: {
            src: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition2.JPG",
            thumbnail: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition2.JPG",
            title: null,
          },
        },
        {
          id: "rtpFoamMixerPosition-3",
          name: "Position 3",
          disabledBy: null,
          image: {
            src: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition3.JPG",
            thumbnail: "/optionImages/rtpFoamMixerPosition/rtpFoamMixerPosition3.JPG",
            title: null,
          },
        },
      ],
    },
  },

  {
    id: "accessories",
    name: "Accessories",
    value: null,
    requires: [],
    description: { value: `If your desired accessory option is not found in the list above, please ask availability from <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
    options: {
      type: "checkbox",
      values: [
        {
          id: "accessories-1",
          name: "Heating duct between volute outlet and manifold",
          disabledBy: null,
          specifications: [
            { title: "Heating duct", value: "Engine coolant water circulates through duct" },
          ],
          image: {
            src: "/optionImages/accessories/heating.JPG",
            thumbnail: "/optionImages/accessories/heating.JPG",
            title: null
          },
        },
        {
          id: "accessories-2",
          name: "Heating duct in pump volute",
          disabledBy: null,
          specifications: [
            { title: "Heating duct", value: "Engine coolant water circulates through duct" },
          ],
          image: {
            src: "/optionImages/accessories/heating.JPG",
            thumbnail: "/optionImages/accessories/heating.JPG",
            title: null
          },
        },
        {
          id: "accessories-3",
          name: "Thermal relief valve 50°C",
          disabledBy: null,
        },
        {
          id: "accessories-4",
          name: "Pressure relief valve 16 Bar",
          disabledBy: null,
        },
        {
          id: "accessories-5",
          name: "Pressure gauge 0…25Bar, Ø80mm",
          disabledBy: null,
          childOptions: [
            {
              id: "accessories-5-child-1",
              name: "Bottom mount",
            },
            {
              id: "accessories-5-child-2",
              name: "Central wall mount",
            }
          ]
        },
        {
          id: "accessories-6",
          name: "Pressure gauge -1…25Bar, Ø80mm",
          disabledBy: null,
          childOptions: [
            {
              id: "accessories-6-child-1",
              name: "Bottom mount",
            },
            {
              id: "accessories-6-child-2",
              name: "Central wall mount",
            }
          ]
        },
        {
          id: "accessories-7",
          name: "Painting",
          disabledBy: null,
          childOptions: [
            {
              id: "accessories-7-child-1",
              name: "Red (ral3001)",
            },
            {
              id: "accessories-7-child-2",
              name: "Other",
            }
          ]
        },
        {
          id: "accessories-8",
          name: "Gearbox hose",
          disabledBy: null,
          specifications: [
            { title: "Gearbox hose", value: "Additional 20mm hydraulic hose for easy draining from oil. Also can be used for refilling when connected to external pump." }
          ],
        },
        {
          id: "accessories-9",
          name: "Anti-vibration pump platform",
          disabledBy: null,
          specifications: [
            { title: "Anti-vibration pump platform", value: "Stainless steel anti-vibration mounting platform for ESTERI fire pumps." }
          ],
          image: {
            src: "/optionImages/accessories/Pump_platform.JPG",
            thumbnail: "/optionImages/accessories/Pump_platform.JPG",
            title: null
          },
        },
        {
          id: "accessories-10",
          name: "Instrument panel",
          disabledBy: null,
          specifications: [
            { title: "Instrument panel", value: `Aluminium panel fixed to ESTERI pump. Standard panel includes up/down speed knob, RPM tachometer with hourmeter, discharge and suction pressure gauges, tank gauge(s). For compatibility with RTP foam system and customized options, please contact <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` },
          ],
          image: {
            src: "/optionImages/accessories/Instrument_panel.JPG",
            thumbnail: "/optionImages/accessories/Instrument_panel.JPG",
            title: null
          },
          childOptions: [
            {
              id: "accessories-10-child-1",
              name: "Low pressure panel, water gauge",
            },
            {
              id: "accessories-10-child-2",
              name: "Low pressure panel, water and foam gauges",
            },
            {
              id: "accessories-10-child-3",
              name: "Low/high pressure panel, water gauge",
            },
            {
              id: "accessories-10-child-4",
              name: "Low/high pressure panel, water and foam gauges",
            },
          ]
        },
        {
          id: "accessories-11",
          name: "ESTERI ON/OFF clutch",
          specifications: [
            { title: "ON/OFF clutch", value: `Clutch for easy engagement/disengagement of ESTERI fire pump (not available for ESTERI 1000). Manual lever engagement as standard. Also available with compressed air or electric 12V/24V engagement. More info from <a target="_blank" rel="noopener" href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95">Esteri Group sales</a>.` }
          ],
          disabledBy: null,
          image: {
            src: "/optionImages/accessories/Clutch.JPG",
            thumbnail: "/optionImages/accessories/Clutch.JPG",
            title: null
          },
        },
      ],
    },
  },
];
