import React from "react";
import SalesConfiguratorNavigation from "features/salesConfigurator/components/SalesConfiguratorNavigation";
import SalesConfiguratorStep from "features/salesConfigurator/components/SalesConfiguratorStep";
import SalesConfiguratorSummary from "features/salesConfigurator/components/SalesConfiguratorSummary";
import Box from "@mui/material/Box";

const SalesConfigurator = () => {
  return (
    <Box sx={{ display: "flex", height: `calc(100% - 64px)` }}>
      <Box sx={{ maxWidth: 350, overflowY: "auto", p: 2 }}>
        <SalesConfiguratorNavigation />
      </Box>
      <Box sx={{ flex: 1, display: "flex", overflowY: "auto" }}>
        <Box sx={{ flex: 2, p: 2 }}>
          <SalesConfiguratorStep />
        </Box>
        <Box sx={{ flex: 1, p: 2 }}>
          <SalesConfiguratorSummary />
        </Box>
      </Box>
    </Box>
  );
};

export default SalesConfigurator;
