import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ValueImage = ({ image }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mr: 2,
        minWidth: "25vw",
        minHeight: 447,
      }}
      key={image.src}
    >
      <div>
        <Box
          component="img"
          sx={{ maxHeight: 440, maxWidth: "100%" }}
          src={image.thumbnail}
          alt={image.title}
        />
      </div>
      <Typography variant="caption">{image.title}</Typography>
    </Box>
  );
};

ValueImage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
};

export default ValueImage;
