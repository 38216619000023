import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const SalesConfiguratorPrice = ({ isSelected, price, hasChildOptions }) => {
  const theme = useTheme();

  if (hasChildOptions && !isSelected) {
    return null;
  }

  return price ? (
    <Typography
      color={isSelected && "primary"}
      sx={{
        ml: 2,
        fontWeight: isSelected ? "medium" : "normal",
        whiteSpace: "nowrap",
        alignSelf: "flex-start",
        transition: theme.transitions.create(["color"], {
          duration: theme.transitions.duration.short,
        }),
      }}
    >
      {`${price} €`}
    </Typography>
  ) : null;
};

SalesConfiguratorPrice.propTypes = {
  isSelected: PropTypes.bool,
  price: PropTypes.number.isRequired,
  hasChildOptions: PropTypes.bool,
};

export default SalesConfiguratorPrice;
