import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const SalesConfiguratorError = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
          maxWidth: 400,
          width: "100%",
          alignItems: "center",
        }}
        elevation={3}
      >
        <ErrorOutlineOutlinedIcon
          sx={{ mb: 2, fontSize: 50, color: "grey.800" }}
        />
        <Typography align="center" variant="h5" paragraph>
          Error loading data
        </Typography>
        <Typography align="center" color="textSecondary" paragraph>
          Wait a moment and try refreshing the page
        </Typography>
      </Paper>
    </Box>
  );
};

export default SalesConfiguratorError;
