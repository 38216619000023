import create from "zustand";
import axios from "axios";

const usePriceStore = create((set) => ({
  prices: new Map(),
  isLoading: false,
  isError: false,

  fetch: async () => {
    set({ isLoading: true, isError: false });
    try {
      const response = await axios.get(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/veikkonummelagetpricesfunction`);
      set((state) => {
        const updatedPrices = new Map(state.prices);
        response.data.forEach((item) => updatedPrices.set(item.id, item.price));
        return { prices: updatedPrices, isLoading: false };
      });
    } catch (error) {
      set({ isError: true, isLoading: false })
      console.log(error);
    }
  },
}));

export default usePriceStore;
