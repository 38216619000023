import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const SalesConfiguratorStart = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
          maxWidth: 1000,
          width: "100%",
        }}
        elevation={3}
      >
        <Typography variant="h5" paragraph>
          Esteri pump configurator
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Esteri pump configurator has been developed for easy fire pump
          construction. Prices in the configurator are directive list prices.
          Final price of the constructed pump will be delivered from Esteri
          sales, after the inquiry has been sent. Images in this configurator
          are for illustration purposes and does not necessarily present the
          selected product completely.
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          All ESTERI fire pumps are designed and manufactured to comply with EN
          1028 standard.
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          As ESTERI fire pumps are known for their versatility and flexibility,
          if the desired configuration cannot be reached with the components in
          this configurator, please contact{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.fwp.fi/contact-information/?lang=en&v=f0aa03aaca95"
          >
            Esteri Group sales
          </a>{" "}
          for customized options.
        </Typography>
        <Button
          sx={{ mt: 2, maxWidth: 300, alignSelf: "center", width: 250 }}
          component={Link}
          to={`/salesConfigurator/corePump`}
          variant="contained"
          color="primary"
        >
          Start
        </Button>
      </Paper>
    </Box>
  );
};

export default SalesConfiguratorStart;
