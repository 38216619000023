import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import usePriceStore from "app/stores/usePriceStore";
import Divider from "@mui/material/Divider";

const SalesConfiguratorSummary = ({ showPlaceOrderButton = true }) => {
  const {
    steps,
    customValues,
    selectedValues,
    selectedChildValues,
  } = useContext(SalesConfiguratorContext);
  const prices = usePriceStore((state) => state.prices);

  const summaryList = useMemo(() => {
    return steps
      .filter((step) => step.value)
      .map(({ id, value, name, options }) => {
        const selectedOption = options.values.find(
          (item) => item.id === value.parentValue
        );

        const specificPriceParent = selectedValues.find((selectedValue) =>
          selectedOption?.specificPrice?.includes(selectedValue)
        );

        const specificPriceChild = selectedChildValues.find(
          (selectedChildValue) =>
            selectedOption?.specificPrice?.includes(selectedChildValue)
        );

        if (selectedOption && selectedOption.type === "customText") {
          const customValue = customValues[id];

          return {
            name,
            selectedName: customValue,
          };
        }

        if (options.type === "checkbox") {
          const valuesWithPrices = value.map((item) => ({
            ...item,
            price: isNaN(
              prices.get(item.childValue ? item.childValue : item.parentValue)
            )
              ? prices.get(item.childValue ? item.childValue : item.parentValue)
              : prices.get(
                  item.childValue ? item.childValue : item.parentValue
                ) * (item.quantity ? item.quantity : 1),
          }));

          return {
            name,
            price: valuesWithPrices.some(
              (value) => value.price === "ask EG for price"
            )
              ? "ask EG for price"
              : valuesWithPrices.reduce((acc, o) => acc + parseInt(o.price), 0),
            selectedName: options.values
              .filter((item) =>
                value.some((valueItem) => valueItem.parentValue === item.id)
              )
              .map((item) => {
                const itemValue = value.find((x) => x.parentValue === item.id);

                const selectedChildOption = item.childOptions?.find(
                  (childOption) => childOption.id === itemValue.childValue
                );

                return item.childOptions
                  ? `${item.name} (${selectedChildOption.name})`
                  : itemValue.quantity
                  ? `${item.name} (${itemValue.quantity} pcs)`
                  : item.name;
              })
              .join(", "),
          };
        }

        return {
          name,
          price: selectedOption.childOptions
            ? prices.get(
                specificPriceParent
                  ? `${value.childValue}-${specificPriceParent}`
                  : value.childValue
              )
            : prices.get(
                specificPriceChild
                  ? `${value.parentValue}-${specificPriceChild}`
                  : value.parentValue
              ),
          selectedName: selectedOption.name,
          selectedChildName: selectedOption.childOptions
            ? selectedOption.childOptions.find(
                (childOption) => childOption.id === value.childValue
              ).name
            : null,
        };
      });
  }, [steps, selectedValues, selectedChildValues, prices, customValues]);

  const totalPrice = summaryList.reduce((acc, o) => {
    return acc + parseInt(o.price && !isNaN(Number(o.price)) ? o.price : 0);
  }, 0);

  if (summaryList.length === 0) return null;

  return (
    <Paper sx={{ padding: 3, marginBottom: 1 }} elevation={3}>
      <Typography sx={{ mb: 2 }} variant="h5">
        Summary
      </Typography>

      {summaryList.map(({ name, selectedName, selectedChildName, price }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 1,
              "& > p": {
                mr: 1,
              },
            }}
            key={name}
          >
            <Typography color="textSecondary">{`${name}:`}</Typography>
            <Typography sx={{ fontWeight: 500 }}>{selectedName}</Typography>
            <Typography>{selectedChildName}</Typography>
            {price && price !== "0" ? (
              <Typography
                color="primary"
                sx={{ fontWeight: "medium" }}
              >{`${price} ${isNaN(price) ? "" : "€"}`}</Typography>
            ) : null}
          </Box>
        );
      })}

      {totalPrice > 0 && (
        <Box sx={{ mt: 2 }}>
          <Divider sx={{ mb: 2 }} />
          <Typography color="textSecondary">Estimated total</Typography>
          <Typography
            color="primary"
            sx={{ fontWeight: "medium" }}
          >{`${totalPrice} €`}</Typography>
        </Box>
      )}

      {showPlaceOrderButton && (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            component={Link}
            to={"/salesConfigurator/order/checkout"}
            variant="contained"
            color="primary"
          >
            Place inquiry
          </Button>
        </Box>
      )}
    </Paper>
  );
};

SalesConfiguratorSummary.propTypes = {
  showPlaceOrderButton: PropTypes.bool,
};

export default SalesConfiguratorSummary;
