import React, { memo } from "react";
import PropTypes from "prop-types";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import SalesConfiguratorNavigationTimelineDot from "features/salesConfigurator/components/SalesConfiguratorNavigationTimelineDot";
import SalesConfiguratorTimeLineLink from "features/salesConfigurator/components/SalesConfiguratorTimeLineLink";
import { useTheme } from "@mui/material/styles";

const SalesConfiguratorNavigationTimeLineItem = ({
  id,
  name,
  isLastItem,
  isDisabled,
}) => {
  const theme = useTheme();

  return (
    <SalesConfiguratorTimeLineLink isDisabled={isDisabled} id={id}>
      <TimelineItem
        sx={{
          minHeight: 55,
          "&::before": {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineSeparator>
          <SalesConfiguratorNavigationTimelineDot routeId={id} />
          {!isLastItem && <TimelineConnector />}
        </TimelineSeparator>

        <TimelineContent sx={{ pt: 0.5 }}>
          {isDisabled ? (
            <Typography color={"textSecondary"}>{name}</Typography>
          ) : (
            <Typography
              sx={{
                textDecoration: "none",
                transition: theme.transitions.create(["color"], {
                  duration: theme.transitions.duration.short,
                }),
                "&:visited": {
                  color: "inherit",
                },
                "&:hover": {
                  color: "primary.main",
                },
              }}
              color={"textPrimary"}
            >
              {name}
            </Typography>
          )}
        </TimelineContent>
      </TimelineItem>
    </SalesConfiguratorTimeLineLink>
  );
};

SalesConfiguratorNavigationTimeLineItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default memo(SalesConfiguratorNavigationTimeLineItem);
