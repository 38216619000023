import React, { useContext, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { stepOptions } from "constants/salesConfiguratorData";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";

const SalesConfiguratorNextButton = () => {
  const { step } = useParams();
  const { selectedValues } = useContext(SalesConfiguratorContext);

  const currentStepIndex = stepOptions.findIndex((item) => item.id === step);

  const nextStep = useMemo(() => {
    const nextSteps = stepOptions.slice(currentStepIndex + 1);

    const nextAllowedStep = nextSteps.find(
      (item) =>
        item.requires.length === 0 ||
        item.requires.some((requirement) =>
          selectedValues.includes(requirement)
        )
    );

    return nextAllowedStep;
  }, [selectedValues, currentStepIndex]);

  return (
    <>
      {stepOptions.length !== currentStepIndex + 1 ? (
        <Button
          component={Link}
          to={`/salesConfigurator/${nextStep.id}`}
          variant="contained"
          color="primary"
        >
          {`Next: ${nextStep.name}`}
        </Button>
      ) : (
        <Button
          component={Link}
          to={"/salesConfigurator/order/checkout"}
          variant="contained"
          color="primary"
        >
          Place inquiry
        </Button>
      )}
    </>
  );
};

export default SalesConfiguratorNextButton;
