import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  TextField,
} from "@mui/material";

const SalesConfiguratorRadioButtonsWithCustomTextOption = ({
  value,
  options,
  handleValueChange,
  handleCustomChange,
  selectedValues,
  customValue,
}) => {
  const customFieldRef = useRef(null);
  const customMultilineFieldRef = useRef(null);

  const handleChange = (event) => {
    if (event.target.name === "customText") {
      customFieldRef.current.focus();
    }

    if (event.target.name === "multilineCustomText") {
      customMultilineFieldRef.current.focus();
    }

    handleValueChange(event.target.value);
  };

  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup value={value ? value : null} onChange={handleChange}>
        {options.map((option) => {
          if (option.type === "customText") {
            return (
              <FormControlLabel
                name="customText"
                key={option.id}
                value={`${option.id}`}
                control={<Radio size="small" color="primary" />}
                label={
                  <TextField
                    variant="standard"
                    inputRef={customFieldRef}
                    value={customValue}
                    placeholder={option.name}
                    onChange={handleCustomChange}
                    inputProps={{
                      sx: { fontSize: 14, fontWeight: "medium" },
                    }}
                    onFocus={() => handleValueChange(option.id)}
                  />
                }
              />
            );
          }
          if (option.type === "multilineCustomText") {
            return (
              <FormControlLabel
                name="multilineCustomText"
                key={option.id}
                value={`${option.id}`}
                control={<Radio color="primary" />}
                label={
                  <TextField
                    inputRef={customMultilineFieldRef}
                    value={customValue}
                    placeholder={option.name}
                    onChange={handleCustomChange}
                    onFocus={() => handleValueChange(option.id)}
                    fullWidth
                    multiline
                  />
                }
              />
            );
          }
          return (
            <FormControlLabel
              key={option.id}
              value={`${option.id}`}
              control={<Radio size="small" color="primary" />}
              label={
                <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                  {option.name}
                </Typography>
              }
              disabled={
                option.disabledBy &&
                option.disabledBy.filter((id) => selectedValues.includes(id))
                  .length > 0
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

SalesConfiguratorRadioButtonsWithCustomTextOption.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  handleValueChange: PropTypes.func.isRequired,
  handleCustomChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
  customValue: PropTypes.string,
};

export default SalesConfiguratorRadioButtonsWithCustomTextOption;
