import React, { useContext } from "react";
import PropTypes from "prop-types";
import TimelineDot from "@mui/lab/TimelineDot";
import { SalesConfiguratorContext } from "features/salesConfigurator/state/SalesConfiguratorContext";
import { useParams } from "react-router-dom";

const SalesConfiguratorNavigationTimelineDot = ({ routeId }) => {
  const { selectedSteps } = useContext(SalesConfiguratorContext);
  const { step } = useParams();

  const isStepDone = selectedSteps.includes(routeId);
  const isStepCurrent = routeId === step;

  if (isStepDone && !isStepCurrent) return <TimelineDot color="primary" />;

  if (isStepCurrent) return <TimelineDot color="primary" variant="outlined" />;

  return <TimelineDot variant="outlined" />;
};

SalesConfiguratorNavigationTimelineDot.propTypes = {
  routeId: PropTypes.string.isRequired,
};

export default SalesConfiguratorNavigationTimelineDot;
