import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import ChildRadioButton from "components/ChildRadioButton";
import QuantitySelector from "components/QuantitySelector";
import usePriceStore from "app/stores/usePriceStore";
import SalesConfiguratorPrice from "features/salesConfigurator/components/SalesConfiguratorPrice";

const SalesConfiguratorCheckboxes = ({
  value,
  options,
  handleValueChange,
  handleCheckboxChildValueChange,
  handleCheckboxQuantityChange,
}) => {
  const prices = usePriceStore((state) => state.prices);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {options.map((option) => {
        const selectedQuantity =
          value?.find((item) => item.parentValue === option.id)?.quantity ?? 1;

        return (
          <div key={option.id}>
            <Box
              sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}
            >
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                control={
                  <Checkbox
                    sx={{ pt: 0 }}
                    onChange={handleValueChange}
                    size="small"
                    name={option.id}
                    color="primary"
                    checked={
                      value
                        ? value.some((item) => item.parentValue === option.id)
                        : false
                    }
                  />
                }
                label={
                  <Typography sx={{ minWidth: 170 }} variant="body2">
                    {option.name}
                  </Typography>
                }
              />
              {(prices.get(option.id) ||
                (option.childOptions &&
                  prices.get(option.childOptions[0]?.id))) && (
                <SalesConfiguratorPrice
                  isSelected={value?.some(
                    (item) => item.parentValue === option.id
                  )}
                  price={
                    option.childOptions
                      ? value?.find((item) => item.parentValue === option.id)
                        ? Number(
                            prices.get(
                              value.find((item) => {
                                return item.parentValue === option.id;
                              }).childValue
                            )
                          )
                        : Number(prices.get(option.childOptions[0].id))
                      : Number(prices.get(option.id) * selectedQuantity)
                  }
                />
              )}
            </Box>
            {option.hasQuantity && (
              <Box sx={{ display: "flex", mb: 3, ml: 4, alignItems: "center" }}>
                <QuantitySelector
                  optionId={option.id}
                  value={value}
                  selectedQuantity={Number(selectedQuantity)}
                  handleCheckboxQuantityChange={handleCheckboxQuantityChange}
                  price={Number(prices.get(option.id))}
                />
              </Box>
            )}
            {value?.some((item) => item.parentValue === option.id) && (
              <RadioGroup
                value={
                  value?.find((item) => item.parentValue === option.id)
                    .childValue
                }
                sx={{ ml: 3 }}
                onChange={(event) =>
                  handleCheckboxChildValueChange(
                    event.target.value,
                    value.find((item) => item.parentValue === option.id)
                      .parentValue
                  )
                }
              >
                {option.childOptions?.map((childOption) => (
                  <ChildRadioButton
                    key={childOption.id}
                    childOption={childOption}
                  />
                ))}
              </RadioGroup>
            )}
          </div>
        );
      })}
    </Box>
  );
};

SalesConfiguratorCheckboxes.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.array,
  handleValueChange: PropTypes.func.isRequired,
  handleCheckboxChildValueChange: PropTypes.func.isRequired,
  handleCheckboxQuantityChange: PropTypes.func.isRequired,
};

export default SalesConfiguratorCheckboxes;
